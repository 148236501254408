/*  */

@import url("https://fonts.googleapis.com/css2?family=Golos+Text:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Poppins", sans-serif !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  margin: 0;
}
.pulse {
  /* animation: shake 2s ease-in-out infinite; */
}
.button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: black;
  border-radius: 100px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
}
@keyframes shake {
  0%,
  10%,
  65%,
  100% {
    transform: rotate(0deg) scale(1);
  }

  30%,
  40% {
    transform: rotate(-1deg) scale(1.05);
  }

  35%,
  45% {
    transform: rotate(1deg) scale(1.05);
  }
}

.home {
  /* background: #ff008a linear-gradient(to bottom right, #ff008a 0%, #d30000 100%); */
  /* height: 84.3vh; */
}
.landing_page img {
  height: 65vh;
  /* height: min-content; */
  mix-blend-mode: hard-light;
}

.bubble {
  border-radius: 24px;
}

.pfp-container {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #f1f1f1;
  /* background-image: url("./assets/default_avatar.png"); */
  background-position: center center;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  display: block;
}

.check_icon {
  height: 15vh;
  width: 15vh;
  display: inline-block;
}
.text_area_handle {
  cursor: not-allowed;
}
.bubble_header {
  /* height: 100px; */
  padding: 16px 16px 12px 16px;
  font-size: 16px;
  display: flex;
  border-radius: 32px 32px 0px 0px;
  border-radius: 32px 32px 0 0;
  align-items: top;
  justify-content: left;
  /* color: white; */
  align-items: center;
}
.user-container {
  /* margin-left: 12px; */
  font-size: 14px;
  /* display: flex;
  align-items: center; */
}

.bubble_header > img {
  width: 27%;
  /* height: 21%; */
  object-fit: contain;
  /* padding: 15px; */
}

.prompt {
  font-weight: 700;
}
.textarea-container {
  width: 100%;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 0 0 24px 24px;
  position: relative;
  -webkit-appearance: none;
}

textarea {
  width: 100%;
  /* height: 150px !important; */
  padding: 18px 15px;
  height: 140px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 600;
  border: none;
  outline: none;
  background-color: transparent;
}
.btn_send_response {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: black;
  border-radius: 100px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 25px;
}

.btn_send_another_response {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  background-color: black;
  border-radius: 100px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 25px;
}

.pulse {
  /* animation: shake 2s ease-in-out infinite; */
}
.button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #5457f9;
  border-radius: 100px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
}
/* @keyframes shake {
  0%,
  10%,
  65%,
  100% {
    transform: rotate(0deg) scale(1);
  }

  30%,
  40% {
    transform: rotate(-1deg) scale(1.05);
  }

  35%,
  45% {
    transform: rotate(1deg) scale(1.05);
  }
} */

.flex-one {
  flex: 1;
}
/* .parent_div {
  height: 80vh;
} */
.Warning_text {
  font-size: 14px;
  color: red;
  font-weight: 400;
}
.terms_div {
  font-size: 17px;
  font-weight: 600;
}
.error_text {
  padding-left: 15px;
}
.web_links {
  font-size: 16px;
  color: white;
  text-decoration: none;
}
.font_set {
  font-size: 14px;
}
.web_links_comp {
  font-size: 14px;
  color: white;
  text-decoration: none;
}
.web_links_comp:hover {
  color: black;
}
.web_links:hover {
  color: black;
}
.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}
.heading-2 {
  color: #000;
  text-align: center;
  font-family: Graphik, sans-serif;
}

h1 {
  margin-top: 20px;
  /* font-size: 38px !important; */
  line-height: 44px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 10px;
  font-weight: bold;
}
h1 {
  margin: 0.67em 0;
  font-size: 2em;
}
.paragraph-5 {
  color: #000;
  text-align: center;
  font-family: Graphik, sans-serif;
  font-size: 16px;
}
.limit_text {
  background: linear-gradient(90deg, #000000, white); /* Default gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.log-image img {
  width: 180px;
}
.f_logo {
  width: 180px;
}
.responsive_image {
  width: 190px;
}
.store_main_bg {
  background-image: url("./assets/left.svg"), url("./assets/right.svg");
  background-color: white;
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  /* background-position: center; */
  border: 1px solid #5457f9;
  border-radius: 10px;
  /* width: 100%;
  height: 20vh; */
}
/* background-image: url("./images/Website/Web_View/TryNow/Elements/SVG/Compressed/Try_Now_BG_ELE_Right\ @3x.svg"),
url("./images/Website/Web_View/TryNow/Elements/SVG/Compressed/Try_Now_BG_ELE_Left\ @3x.svg");
background-position: right top, left bottom;
background-repeat: no-repeat, no-repeat;
height: 100vh;
background-color: white; */
.text_head {
  font-size: 16px;
  font-weight: 600;
  color: #4f4f4f;
  line-height: 1.4;
}
.text_main {
  color: #5457f9 !important;
  font-size: 25px;
  font-weight: 700;
}
.text_like_this {
  font-size: 20px;
  font-weight: 600;
  color: #4f4f4f;
}
.logo_handle {
  font-size: 18px;
  font-weight: 600;
  color: #4f4f4f;
  margin-top: 10px;
}
/* .img_handler {
  margin-top: 10vh;
} */
.postError_text {
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  font-weight: 700;
}
.error_image {
  width: 230px;
}
.postError_text_sub {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
}
.error_button {
  font-family: "Poppins", sans-serif;
  background-color: #5457f9;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: white;
  border-radius: 30px;
  /* width: 183px;
  height: 49px; */
  border: none;
  padding: 13px 30px;
  /* margin-top: 26px; */
}
@media (min-width: 767px) {
  textarea {
    width: 707px;
  }
  .footer_bg_custom {
    width: 707px !important;
  }
}

@media (max-width: 767px) {
  .bubble_header > img {
    width: 35%;
  }
  .bubble_header {
    display: block !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding: 16px 16px 12px 0px;
  }
  .bubble_header > img {
    padding: 0px !important;
  }
  .user-container {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .bubble_header > img {
    margin-left: 20px;
  }
  .btn_send_another_response {
    width: 100%;
  }
  textarea {
    padding: 18px 18px;
  }
  .error_text {
    padding-left: 19px;
  }
}
textarea {
  resize: none !important;
  /* overflow: auto; */
}

@media (max-width: 590px) {
  .landing_page img {
    height: 40vh;
  }
  .Warning_text {
    font-size: 12px;
  }
  textarea ::placeholder {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .text_main {
    font-size: 20px;
  }
  .text_like_this {
    font-size: 18px;
  }
}

@media (min-width: 568px) and (max-width: 635px) {
  .store_main_bg {
    padding-left: 14px;
    padding-right: 14px;
  }
}
@media (min-width: 576px) and (max-width: 632px) {
  .logo_handle {
    /* margin-top: 0px; */
  }
}
@media (min-width: 452px) and (max-width: 499px) {
  .logo_handle {
    margin-top: 0px;
  }
}
@media (max-width: 452px) {
  .logo_handle {
    margin-top: 0px;
  }
}

@media (max-width: 425px) {
  .responsive_image {
    width: 190px;
  }
}
