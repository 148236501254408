:root {
  --vh: 100%;
}

.full-height {
  /* height: 100%; */
  min-height: calc(var(--vh, 1vh) * 100);
}

.gradient-background {
  background: linear-gradient(
    360deg,
    #ff9534 0%,
    #fff188 9%,
    #6dff8a 39%,
    #00dfff 70%,
    #9968ff 86%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.new_mobile_image_new {
  width: 100%;
  object-fit: contain;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.heading_text {
  font-size: 56px;
  font-weight: 700;
}

.responsive_heading_field {
  margin-bottom: 15px;
}

.arrow-icon {
  height: auto;
  animation: moveUpDown 2s infinite;
}
.border_radius_Custom {
  border-radius: 20px;
}
.image_hover_1 {
  transform: rotate(-6.5deg);
  transition: transform 0.3s ease-in-out 0.3s;
  border-radius: 20px;
  border: 3px solid transparent;
}

.image_hover_1:hover {
  opacity: 1;
  position: relative;
  transform: rotate(0deg) scale(1.1);
  z-index: 1;
  border: 3px solid white;
  border-radius: 20px;
}
.image_hover_2 {
  transform: rotate(4.5deg);
  transition: transform 0.3s ease-in-out 0.3s;
  border-radius: 20px;
  border: 3px solid transparent;
}
.image_hover_2:hover {
  opacity: 1;

  position: relative;
  z-index: 1;
  transform: rotate(0deg) scale(1.1);
  border: 3px solid white;
  border-radius: 20px;
}
.image_hover_3 {
  transform: rotate(-6.5deg);
  transition: transform 0.3s ease-in-out 0.3s;
  border-radius: 20px;
  border: 3px solid transparent;
}
.image_hover_3:hover {
  opacity: 1;

  position: relative;
  z-index: 1;
  transform: rotate(0deg) scale(1.1);
  border: 3px solid white;
  border-radius: 20px;
}
.image_hover_4 {
  transform: rotate(4.5deg);
  transition: transform 0.3s ease-in-out 0.3s;
  border-radius: 20px;
  border: 3px solid transparent;
}
.image_hover_4:hover {
  opacity: 1;
  position: relative;
  z-index: 1;
  transform: rotate(0deg) scale(1.1);
  border: 3px solid white;
  border-radius: 20px;
}
.image_hover:hover {
  cursor: pointer;
  transform: rotate(0deg);
}
.main_comp1 {
  height: 100%;
}

.comp1_main {
  /* background-image: url("./images/Website//Web_View/Hero_Banner/BG/Compressed/Hero_banner_BG_1366.png"); */
  background-image: url("./images/Website//Web_View/Hero_Banner/BG/Compressed/bg.svg");
  /* height: 100vh; */
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.comp1_custom_style {
  margin-top: 80px;
}
.home_comp1_text {
  font-size: 67px;
  font-weight: 700;
  color: #2e2e2e;
  line-height: 1.1;
}
.text_blue {
  color: #5457f9;
}
.sub_title_text {
  margin-top: 20px;
  color: #2e2e2e;
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
}
.store_icons {
  margin-top: 20px;
}

.arrow_position {
  width: fit-content;
  bottom: 3%;
  left: 41%;
}

.comp2_main {
  background-image: url("./images/Website/Web_View/BG_Elements/SVG/Compressed/Elements_01.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: top;
  height: 100%;
}
.image_size {
  margin-top: 55px;
}

.comp_second_custom {
  padding-top: 208px;
}
.comp_2_custom {
  font-size: 20px;
  font-weight: 600;
}
.comp_2_custom_text {
  font-size: 20px;
  font-weight: 500;
}

.comp2_custom {
  margin-bottom: 143px;
  padding-bottom: 70px;
}

.comp3_custom {
  background-image: url("./images/Website/Web_View/BG_Elements/SVG/Compressed/Elements_02.svg");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;
  height: 100%;
}

.comp4_custom {
  background-image: url("./images/Website//Web_View/BG_Elements/SVG/Compressed/Elements_03.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: top;

  height: 100%;
}
.comp5_custom {
  background-image: url("./images/Website/Web_View/BG_Elements/SVG/Compressed/Elements_04.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-position-y: top;

  height: 100%;
}
.comp6_custom {
  background-image: url("./images/Website/Web_View/BG_Elements/SVG/Compressed/Elements_05.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: top;

  height: 100%;
}
.comp7_custom {
  background-image: url("./images/Website/Web_View/BG_Elements/SVG/Compressed/Elements_06.svg");
  background-repeat: no-repeat;
  background-position: left;
  height: 100%;
  background-position-y: top;
}

.faq_main {
  padding-top: 158px;
}
.accordion-item:has(.show) {
  border: 2px solid #5457f9;
}
.accordion-item {
  border: 2px solid white;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("./images/acc_svg.svg");
  background-size: contain;
  width: 40px;
  height: 40px;
}

/* .show .accordion-button:not(.collapsed)::after {
  border: 2px solid #5457f9;
} */
.accordion-button::after {
  background-image: url("./images/Group.svg");
  background-size: contain;
  width: 40px;
  height: 40px;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: #2e2e2e;
  font-weight: 500;
  font-size: 20px;
}
.accordion-item {
  border-radius: 18px !important;
}

.accordion-body {
  padding: 0px 51px 30px 39px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button {
  font-size: 20px;
  font-weight: 500;
  padding: 30px 39px 30px 39px;
  border-radius: 18px !important;
}
.accordion-body {
  color: #707070;
  font-weight: 400;
}

.web_footer {
  background-image: url("./images/Website/Web_View/TryNow/Elements/SVG/Compressed/Try_Now_BG_ELE_Right\ @3x.svg"),
    url("./images/Website/Web_View/TryNow/Elements/SVG/Compressed/Try_Now_BG_ELE_Left\ @3x.svg");
  background-position: right top, left bottom;
  background-repeat: no-repeat, no-repeat;
  height: 100vh;
  background-color: white;
}
.store_icons button img {
  width: 180px;
  height: 52px;
}
.footer_main {
  background-color: #5457f9;
}
.arrow_position {
  width: fit-content;
  bottom: 3%;
  left: 40%;
}
.accordion-header {
  /* border: 2px solid white; */
  border-radius: 20px;
}

.accordion-item:not(:first-of-type) {
  border: 2px solid white;
}
.accordion-item:not(.show):hover {
  border: 2px solid #5457f9;
}
.accordion-item {
  border: 2px solid white;
}

/* .accordion-button:is(.collapsed):hover {
  border: 2px solid #5457f9;
} */

/* .accordion-button:not(:has(.show)):hover {
  background-color: #5457f9;
} */
@media (min-width: 2048px) {
  .comp1_main {
    /* min-height: 100vh; */
    /* padding-bottom: 289px !important; */
  }
}

@media (min-width: 1760px) {
  .arrow_position {
    left: 40.5% !important;
  }
  .comp1_main {
    padding-bottom: 52px !important;
  }
}

@media (min-width: 1700px) {
  .comp1_custom_style {
    margin-top: 65px;
  }
}
/* 
@media (min-width: 1600px) {
  .comp1_main { 
    background-image: url("./images/mini_images/Hero_banner_BG_1440\ @2x.svg");
  }
  .arrow_position {
    left: 42.5%;
  }
} */

@media (min-width: 1600px) {
  .comp1_main {
    background-image: url("./images/home_banner_1600.svg");
    /* background-position: center; */
    /* max-width: 1600px; */
  }
}
@media (min-width: 1600px) {
  .container-1600 {
    width: 100%;
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1450px) {
  .comp1_main {
    /* min-height: 100vh; */
    padding-bottom: 117px;
  }
}
@media (max-width: 1260px) {
  .comp1_main {
    background-size: cover;
    /* min-height: 100vh; */
  }
  .home_comp1_text {
    font-size: 55px;
  }
}
@media (min-width: 1219px) {
  .arrow_position {
    left: 39%;
  }
}
@media (min-width: 1402px) and (max-width: 1490px) {
  /* .store_icons {
    margin-left: 11%;
  } */
  .comp1_custom_style {
    margin-top: 50px;
  }
}
@media (max-width: 1366px) {
  .comp1_main {
    /* background-image: url("./images/Hero_banner_BG_1366\ @3x.svg"); */
  }
}
@media (min-width: 1260px) and (max-width: 1450px) {
  .comp1_custom_style {
    margin-top: 40px;
  }
  .comp2_custom {
    padding-bottom: 120px;
  }
}
@media (min-width: 1259px) and (max-width: 1391px) {
  .arrow_position {
    left: 40%;
  }
}
@media (min-width: 1219px) and (max-width: 1240px) {
  .arrow_position {
    left: 38.5%;
  }
}

@media (min-width: 999px) and (max-width: 1200px) {
  .arrow_position {
    left: 38.5%;
  }
}
@media (min-width: 1424px) and (max-width: 1760px) {
  .accordion {
    margin: 0px 19%;
  }
  .faq_main {
    margin: 0px 16%;
  }
}
@media (min-width: 1051px) and (max-width: 1424px) {
  .faq_main {
    margin: 0px 16%;
  }
  .accordion {
    margin: 0px 19%;
  }
  .accordion-button:not(.collapsed)::after {
    width: 35px;
    height: 35px;
  }
  .accordion-button::after {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 1199px) {
  .custom_lg_style {
    width: 100%;
  }
}
@media (min-width: 767px) and (max-width: 1424px) {
  .custom_width_Set {
    width: 100% !important;
  }
}
@media (min-width: 767px) and (max-width: 799px) {
  .arrow_position {
    left: 37%;
  }
}
@media (max-width: 1050px) {
  .accordion-body {
    padding: 0px 30px 30px 39px;
  }
  .home_comp1_text {
    /* margin-top: 76px; */
    font-size: 50px;
    font-weight: 700;
    color: #2e2e2e;
    line-height: 1;
  }
  .comp2_main {
    /* background-image: url("./images//comp2_vector_mobile.svg"); */
    background-image: url("./images/Website/Mobile_View/BG_Elements/SVG/Comp/Elements_Mobile_View_01@3x.svg");

    background-repeat: no-repeat;
    background-position-y: top;
    /* height: 100%; */
  }
  .comp3_custom {
    /* background-image: url("./images/comp4_vector_mmobile.svg"); */
    background-image: url("./images/Website/Mobile_View/BG_Elements/SVG/Comp/Elements_Mobile_View_02.svg");

    background-repeat: no-repeat;
    background-position-y: top;
    height: 100%;
    color: #2e2e2e;
  }

  .comp4_custom {
    /* background-image: url("./images/comp5_vector_mobile.svg");
     */
    background-image: url("./images/Website/Mobile_View/BG_Elements/SVG/Comp/Elements_Mobile_View_03.svg");

    background-repeat: no-repeat;
    background-position-y: top;
    height: 100%;
  }

  .comp5_custom {
    /* background-image: url("./images/comp6_vector_mobile.svg"); */
    background-image: url("./images/Website/Mobile_View/BG_Elements/SVG/Comp/Elements_Mobile_View_04.svg");

    background-repeat: no-repeat;
    background-position-y: top;
    height: 100%;
  }

  .comp6_custom {
    /* background-image: url("./images//comp7_vector_mobile.svg"); */
    background-image: url("./images/Website/Mobile_View/BG_Elements/SVG/Comp/Elements_Mobile_View_05.svg");

    background-repeat: no-repeat;
    background-position-y: top;
    height: 100%;
    /* background-attachment: unset; */
  }
  .comp7_custom {
    /* background-image: url("./images/comp8_mobile_vector.svg"); */
    background-image: url("./images/Website/Mobile_View/BG_Elements/SVG/Comp/Elements_Mobile_View_06.svg");

    background-repeat: no-repeat;
    background-position-y: top;
    height: 100%;
    background-attachment: unset;
  }
}
@media (max-width: 991px) {
  /* .image_hover_3 {
    transform: rotate(4.5deg);
    transition: transform 0.3s ease-in-out 0.3s;
    border-radius: 20px;
    border: 3px solid transparent;
  }
  .image_hover_3:hover {
    opacity: 1;
    position: relative;
    z-index: 1;
    transform: rotate(0deg) scale(1.1);
    border: 3px solid white;
    border-radius: 20px;
  }
  .image_hover_2 {
    transform: rotate(-6.5deg);
    transition: transform 0.3s ease-in-out 0.3s;
    border-radius: 20px;
    border: 3px solid transparent;
  }
  .image_hover_2:hover {
    opacity: 1;
    position: relative;
    transform: rotate(0deg) scale(1.1);
    z-index: 1;
    border: 3px solid white;
    border-radius: 20px;
  } */

  .store_icons button img {
    width: 152px;
    height: 46px;
  }

  .comp1_custom_style {
    margin-top: 35px;
  }
  .movile_main_image img {
    width: 50%;
  }
  .movile_main_image_fifth_six img {
    width: 40%;
  }
  .home_comp1_text {
    margin-top: 20px;
    font-size: 45px;
    font-weight: 700;
    color: #2e2e2e;
    /* line-height: 0.6; */
  }
  .sub_title_text {
    margin-top: 17px;
    color: #2e2e2e;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    padding-right: 20px;
  }
  .store_icons {
    margin-top: 0px;
  }
  .comp2_custom {
    margin-bottom: 78px;
  }
  .comp_second_custom {
    padding-top: 145px;
  }
  .new_mobile_image {
    width: 50%;
  }
  .new_mobile_image_new {
    width: 59%;
  }

  .reponsive_margin {
    margin: 10px 0px;
    text-align: center;
  }
}

@media (min-width: 908px) and (max-width: 991px) {
  .comp1_main {
    min-height: 65vh;
  }
  .comp2_custom {
    padding-bottom: 106px;
  }
}
@media (min-width: 768px) and (max-width: 905px) {
  .comp1_main {
    min-height: 45vh;
  }
}

@media (min-width: 668px) and (max-width: 767px) {
  .arrow_position {
    left: 32% !important;
  }
}
@media (min-width: 598px) and (max-width: 667px) {
  .arrow_position {
    left: 29% !important;
  }
}
@media (min-width: 580px) and (max-width: 597px) {
  .arrow_position {
    width: fit-content;
    bottom: 2%;
    left: 28% !important;
  }
}
@media (max-width: 580px) {
  .responsive_size img {
    width: 225px !important;
    height: 65px !important;
  }
}
@media (min-width: 502px) and (max-width: 580px) {
  .arrow_position {
    width: fit-content;
    bottom: 2%;
    left: 25%;
  }
}
@media (min-width: 490px) and (max-width: 767px) {
  .comp2_custom {
    padding-bottom: 140px;
  }
}
@media (min-width: 490px) and (max-width: 502px) {
  .arrow_position {
    width: fit-content;
    bottom: 2%;
    left: 29%;
  }
}
@media (min-width: 1200px) and (max-width: 1260px) {
  .arrow_position {
    width: fit-content;
    bottom: 2%;
    left: 38.5%;
  }
}
@media (min-width: 950px) and (max-width: 1000px) {
  .arrow_position {
    width: fit-content;
    bottom: 2%;
    left: 38.5%;
  }
}
@media (min-width: 900px) and (max-width: 950px) {
  .arrow_position {
    width: fit-content;
    bottom: 2%;
    left: 38.5%;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .arrow_position {
    width: fit-content;
    bottom: 2%;
    left: 38.5%;
  }
}
@media (max-width: 767px) {
  .heading_text {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
  }
  .web_footer {
    background-image: url("./images/mini_images/Try_Now_BG_ELE_Mobile.svg");
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 100%;
    /* background-size: cover; */
    height: 500px;
  }
  .accordion-button:not(.collapsed)::after {
    width: 35px;
    height: 35px;
  }

  .accordion-button::after {
    width: 35px;
    height: 34px;
  }

  .comp_2_custom {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .responsive_heading_field {
    margin-bottom: 10px;
  }
  .comp_2_custom_text {
    font-size: 16px;
    font-weight: 500;
    /* margin-top: 10px; */
  }
}

@media (max-width: 575px) {
  /*  */
  .handle_pipe {
    display: none;
  }
  .store_icons {
    width: 100%;
  }
  .web_footer {
  }
}
@media (min-width: 490px) and (max-width: 580px) {
  .responsive_margin {
    padding: 15px 30px 0px 30px;
  }
}

@media (max-width: 490px) {
  .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
  }
  .accordion-button::after {
    width: 27px;
    height: 27px;
  }
  .accordion-body {
    font-size: 12px;
  }
  /* .accordion-button {
    padding: 41px 39px 10px 39px;
  } */
  /* .vh_height {
    min-height: 88vh;
  } */
  .accordion-button:not(.collapsed) {
    font-size: 16px;
  }
  .accordion-button {
    font-size: 16px;
    padding: 20px 20px 20px 25px;
  }
  .accordion-body {
    padding: 0px 21px 30px 25px;
  }
  .store_icons {
    width: 100%;
  }
  .comp1_main {
    background-image: url("./images/Website/Mobile_View/HeroBanner/Compressed/Group\ 1000004204.svg");
    background-position: center, top;
    background-repeat: no-repeat;
    /* background-position: center center; */
    background-size: cover;
    min-height: 100vh;
    /* padding-bottom: 181px; */
  }
  .custom_width_Set {
    width: 70% !important;
  }
  .responsive_heading_field {
    margin-bottom: 10px;
  }

  .responsive_margin {
    padding: 15px 30px 0px 30px;
  }

  .arrow_position {
    /* width: -moz-fit-content; */
    width: fit-content;
    bottom: 1%;
    left: 65%;
  }
  .main_page_responsive_view {
    /* padding: 0px 10px; */
  }
  .responsive_view {
    text-align: center;
  }

  .comp1_custom_style {
    margin-top: 20px;
  }
  .home_comp1_text {
    margin-top: 0px;
    font-size: 35px;
    font-weight: 700;
    color: #2e2e2e;
    line-height: 1.1;
  }
  .sub_title_text {
    font-size: 14px;
  }
  .store_icons {
    margin-top: 0px;
    /* border-radius: 10px; */
  }

  .comp_2_custom {
    font-size: 12px;
  }
  .comp_2_custom_text {
    font-size: 14px;
  }
  .movile_main_image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .new_mobile_image {
    width: 65%;
  }
  .new_mobile_image_new {
    width: 77%;
  }
  .movile_main_image_fifth_six img {
    width: 51%;
  }
}
@media (max-width: 433px) {
  .comp1_main {
    min-height: 90vh;
  }
}

@media (max-width: 373px) {
  .accordion-button:not(.collapsed) {
    font-size: 16px;
  }
  .store_icons {
    /* flex-direction: column; */
    margin-top: -12px;
  }
  .specific_btn {
    margin-top: 1.4rem;
  }
  .specfic_btn_one {
  }
}

@media (min-width: 373px) {
  .specific_btn {
    /* margin-left: 1.4rem; */
  }
}
